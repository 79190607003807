import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IButtonStyles,
    IStackTokens,
    PrimaryButton,
    Stack,
    Text,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { CancelToken } from 'axios';
import { usePromise } from '../../Common/hooks/usePromise';
import { ReleaseSearchResult } from '../../AGSS/models/Release';
import { getReleaseList } from '../../AGSS/api/AGSSApi';
import { HomeReleaseList } from './HomePageLayout/HomeReleaseList';
import { HomeHeroSection } from './HomePageLayout/HomeHeroSection';
import { PageContent } from './PageLayout/PageContent';
import { AuthContext } from '../models/AuthContext';
import { clearUserProfileRecents } from '../../Common/api/ABHub/UserProfileApi';
import { useIsMobile } from '../../Common/hooks/useIsMobile';
import { MobileReleaseList } from '../../AGSS/components/MultistageReleaseData/Display/Mobile/MobileReleaseList';

export const HomePage: React.FC = () => {
    const sectionStackTokens: IStackTokens = { childrenGap: 20 };
    const [items, setItems] = useState<ReleaseSearchResult[]>([]);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const isMobile = useIsMobile();

    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 120,
            maxWidth: 120,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: '5px',
            borderWidth: '2px',
        },
    };

    const modalProps = {
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
    };

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Clear History',
        subText: 'Are you sure you want to clear history?',
    };

    const { authContext, setDbUpdated, isUpdated } = useContext(AuthContext);

    const getLatestReleaseData = useCallback(
        async (cancelToken?: CancelToken) => {
            const releasesFromUserProfile =
                authContext.userProfile?.recentlyViewedReleases;

            if (releasesFromUserProfile) {
                const recentMap = new Map<string, Date>(
                    Object.entries(releasesFromUserProfile)
                );

                const releaseFromUserProfileArr = Array.from(recentMap.keys());

                if (releaseFromUserProfileArr.length > 0) {
                    const recentReleases: ReleaseSearchResult[] =
                        await getReleaseList(
                            [],
                            releaseFromUserProfileArr,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            1,
                            cancelToken
                        );

                    return recentReleases.sort(
                        (a, b) =>
                            releaseFromUserProfileArr.indexOf(
                                a.releaseCorrelationId
                            ) -
                            releaseFromUserProfileArr.indexOf(
                                b.releaseCorrelationId
                            )
                    );
                }
            }
        },
        [authContext, authContext.userProfile]
    );

    const clearRecentlyViewedReleasesFromUserProfile = useCallback(
        async (cancelToken?: CancelToken) => {
            if (authContext.userProfile) {
                const result = await clearUserProfileRecents(
                    authContext.userProfile?.alias,
                    true,
                    cancelToken
                );

                if (setDbUpdated && result) {
                    setDbUpdated(true);
                }

                return result;
            }
        },
        [authContext]
    );

    const [
        latestReleases,
        latestReleasesError,
        releasesLoaded,
        grabRecentReleases,
    ] = usePromise(getLatestReleaseData, true); // Release Data

    const [
        clearedListResult,
        clearedListError,
        clearedListLoaded,
        startClearing,
    ] = usePromise(clearRecentlyViewedReleasesFromUserProfile, false);

    useEffect(() => {
        if (isUpdated) {
            grabRecentReleases();
        }

        if (releasesLoaded) {
            setItems(latestReleases ?? []);
        }

        if (latestReleasesError) {
            console.error(latestReleasesError);
        }
    }, [latestReleases, releasesLoaded, latestReleasesError, isUpdated]);

    useEffect(() => {
        if (clearedListLoaded) {
            grabRecentReleases();
        }
    }, [clearedListLoaded]);

    const clearHistory = () => {
        startClearing();

        toggleHideDialog();
        setItems([]);

        if (clearedListError) {
            console.error(clearedListError);
        }
    };

    return (
        <div className="HomePage-root">
            <Stack
                horizontalAlign="center"
                verticalAlign="center"
                tokens={sectionStackTokens}
            >
                <HomeHeroSection />
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    <div>
                        <Stack tokens={sectionStackTokens}>
                            <Stack
                                horizontal
                                style={{ justifyContent: 'space-between' }}
                            >
                                <Text variant="xLarge">Recent Releases</Text>
                                {releasesLoaded && items.length > 0 && (
                                    <DefaultButton
                                        styles={buttonStyles}
                                        text="Clear History"
                                        onClick={toggleHideDialog}
                                        iconProps={{ iconName: 'Delete' }}
                                    />
                                )}
                            </Stack>
                            {isMobile ? (
                                <MobileReleaseList
                                    list={items}
                                    isLoaded={releasesLoaded}
                                    isHomePage
                                />
                            ) : (
                                <HomeReleaseList
                                    list={items}
                                    isLoaded={releasesLoaded}
                                />
                            )}

                            <Dialog
                                hidden={hideDialog}
                                onDismiss={toggleHideDialog}
                                dialogContentProps={dialogContentProps}
                                modalProps={modalProps}
                            >
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={clearHistory}
                                        text="Yes"
                                    />
                                    <DefaultButton
                                        onClick={toggleHideDialog}
                                        text="No"
                                    />
                                </DialogFooter>
                            </Dialog>
                        </Stack>
                    </div>
                </div>
            </Stack>
        </div>
    );
};
