import { defaultTheme } from '../../HubLayout/styles/defaultTheme';
import { darkTheme } from '../../HubLayout/styles/darkTheme';
import { Theme } from '@fluentui/react';

//set theme preference based on localStorage
export const setThemeInLocalStorage = (theme: string) => {
    localStorage.setItem('themePreference', theme);
};

//get theme from localStorage
export const getThemeFromLocalStorage = (): 'dark' | 'default' => {
    const theme = localStorage.getItem('themePreference');
    if (theme && theme === 'dark') {
        return 'dark';
    }
    return 'default';
};

//get theme from string
export const getThemeFromString = (themeName: string | undefined): Theme => {
    return themeName
        ? themeName === 'dark'
            ? darkTheme
            : defaultTheme
        : defaultTheme;
};

// set search value in localStorage
export const setMultiStageReleaseSearchValueInLocalStorage = (
    searchValue: string
) => {
    localStorage.setItem('multiStageReleaseSearchValue', searchValue);
};

// set search value in localStorage
export const setMultiStageBuildSearchValueInLocalStorage = (
    searchValue: string
) => {
    localStorage.setItem('multiStageBuildSearchValue', searchValue);
};

// set start date in localStorage
export const setStartDateInLocalStorage = (searchValue: string) => {
    localStorage.setItem('startDate', searchValue);
};

// set end date in localStorage
export const setEndDateInLocalStorage = (searchValue: string) => {
    localStorage.setItem('endDate', searchValue);
};

// get start date value in localStorage
export const getStartDateInLocalStorage = () => {
    const searchValue = localStorage.getItem('startDate');
    return searchValue ? searchValue : undefined;
};

// get end date value in localStorage
export const getEndDateInLocalStorage = () => {
    const searchValue = localStorage.getItem('endDate');
    return searchValue ? searchValue : undefined;
};

// get search value in localStorage
export const getMultiStageReleaseSearchValueFromLocalStorage = () => {
    const searchValue = localStorage.getItem('multiStageReleaseSearchValue');
    return searchValue ? searchValue : undefined;
};

// get search value in localStorage
export const getMultiStageBuildSearchValueFromLocalStorage = () => {
    const searchValue = localStorage.getItem('multiStageBuildSearchValue');
    return searchValue ? searchValue : undefined;
};

// set selected service names in localStorage
export const setSelectedServiceNamesInLocalStorage = (
    selectedKeys: string[]
) => {
    localStorage.setItem('selectedServiceNames', JSON.stringify(selectedKeys));
};

// get selected service names in localStorage
export const getSelectedServiceNamesFromLocalStorage = () => {
    const selectedServiceNames = localStorage.getItem('selectedServiceNames');

    if (selectedServiceNames) {
        const paredServiceNames = JSON.parse(selectedServiceNames);
        return paredServiceNames as string[];
    }
};

// set selected project names in localStorage
export const setSelectedProjectNamesInLocalStorage = (
    selectedKeys: string[]
) => {
    localStorage.setItem('selectedProjetNames', JSON.stringify(selectedKeys));
};

// get selected project names in localStorage
export const getSelectedProjectNamesFromLocalStorage = () => {
    const selectedProjectNames = localStorage.getItem('selectedProjectNames');

    if (selectedProjectNames) {
        const paredprojectNames = JSON.parse(selectedProjectNames);
        return paredprojectNames as string[];
    }
};

// set selected enivornment names in localStorage
export const setSelectedEnvironmentNamesInLocalStorage = (
    selectedKeys: string[]
) => {
    localStorage.setItem(
        'selectedEnvironmentNames',
        JSON.stringify(selectedKeys)
    );
};

// set selected deployment types in localStorage
export const setSelectedDeploymentTypesInLocalStorage = (
    selectedKeys: string[]
) => {
    localStorage.setItem(
        'selectedDeploymentTypes',
        JSON.stringify(selectedKeys)
    );
};

// get selected environment names in localStorage
export const getSelectedEnvironmentNamesFromLocalStorage = () => {
    const selectedEnvironmentNames = localStorage.getItem(
        'selectedEnvironmentNames'
    );

    if (selectedEnvironmentNames) {
        const paredEnvironmentNames = JSON.parse(selectedEnvironmentNames);
        return paredEnvironmentNames as string[];
    }
};

// get selected deployment types in localStorage
export const getSelectedDeploymentTypesFromLocalStorage = () => {
    const selectedDeploymentTypes = localStorage.getItem(
        'selectedDeploymentTypes'
    );

    if (selectedDeploymentTypes) {
        const paredDeploymentTypes = JSON.parse(selectedDeploymentTypes);
        return paredDeploymentTypes as string[];
    }
};
