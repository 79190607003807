import React, {
    CSSProperties,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ReleaseStage } from '../../../../models/ReleaseStage';
import { ServiceType } from '../../../../../ABSM/models/ServiceType';
import { Guid } from '../../../../../Common/models/Guid';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { Deployment } from '../../../../models/Deployment';
import { Build } from '../../../../models/Build';
import {
    CommandButton,
    DocumentCardActivity,
    IconButton,
    IContextualMenuItem,
    IDocumentCardActivityStyles,
    IStackTokens,
    Stack,
    Text,
} from '@fluentui/react';
import { CompletionIndicatorIcon } from '../Status/CompletionIndicatorIcon';
import { getStatusIconProperties } from '../../../../models/Timeline';
import { MobileReleaseStageEntityInfo } from './MobileReleaseStageEntityInfo';
import { ReleaseStageCompactDetails } from '../ReleaseStageCompactDetails';
import { AgrmDetailsPanel } from '../../../Deployment/Mobile/AgrmDetailsPanel';
import { AGRMDataType } from '../../../../models/AGRMDataType';
import { checkValidCloudOrEnvironment } from '../../../../api/AGRMApi';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../../../Common/api/ABHub/ABHubGlobalConfig';

interface IMobileReleaseStageDetailsProps {
    isLoaded: boolean;
    stage: ReleaseStage | undefined;
    setSelected: Dispatch<SetStateAction<Deployment | Build | undefined>>;
    showPanel: () => void;
    showMetadataPanel: () => void;
    isPanelOpen: boolean;
    serviceType?: ServiceType;
    serviceId?: Guid;
}

export const MobileReleaseStageDetails: React.FC<IMobileReleaseStageDetailsProps> =
    (props: IMobileReleaseStageDetailsProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);

        const [stage, setStage] = useState<ReleaseStage>();
        const [expanded, setExpanded] = useState<boolean>();
        const [selectedAgrmOption, setSelectedAgrmOption] =
            useState<AGRMDataType>();
        const [selected, setSelected] = useState<
            Deployment | Build | undefined
        >();
        const [isAgrmPanelOpen, setIsAgrmPanelOpen] = useState<boolean>(false);
        const showAgrmPanel = () => setIsAgrmPanelOpen(true);
        const hideAgrmPanel = () => setIsAgrmPanelOpen(false);

        const divStyles = (status: string | undefined): CSSProperties => {
            let color = getStatusIconProperties(status, theme).iconColor;
            return {
                borderLeft: 'solid 1px ' + theme.palette.neutralLight,
                borderRight: 'solid 1px ' + theme.palette.neutralLight,
                borderBottom: 'solid 1px ' + theme.palette.neutralLight,
                boxShadow: '0 -8px 0 0 ' + color,
                backgroundColor: theme.palette.white,
                height: 'fit-content',
                padding: 16,
                margin: '10px 0px 20px 0px',
            };
        };

        const stackTokens: IStackTokens = { childrenGap: 10 };
        const activityStyles: Partial<IDocumentCardActivityStyles> = {
            root: { width: 200, backgroundColor: theme.palette.white },
        };

        // When parent component sends new data, refresh my state accordingly
        useEffect(() => {
            if (props.stage) {
                //set stage
                setStage(props.stage);

                if (props.stage.deployments.length > 0) {
                    props.stage.deployments.map((deployment) => {
                        deployment.showMetadata = true;
                    });
                }

                if (props.stage.builds.length > 0) {
                    props.stage.builds.map((build) => {
                        build.showMetadata = true;
                    });
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.stage, props.isLoaded]);

        const expandRelease = () => {
            setExpanded(!expanded);
        };

        const showMetadataPanelClick = (entity: Deployment | Build) => {
            props.setSelected(entity);
            props.showMetadataPanel();
        };

        const showAgrmtDataClick = (
            deployment: Deployment,
            option: AGRMDataType
        ) => {
            setSelected(deployment);
            setSelectedAgrmOption(option);
            showAgrmPanel();
        };

        const environment = getConfig(GlobalConfigProperties.Environment);
        const cloud = getConfig(GlobalConfigProperties.Cloud);

        const isValidEnv = (cld: string, env: string) => {
            try {
                checkValidCloudOrEnvironment(cld, env);
                return true;
            } catch {
                return false;
            }
        };

        const renderAgrmButton = (
            item: any,
            dismissMenu: (ev?: any, dismissAll?: boolean) => void
        ) => {
            return isValidEnv(cloud, environment) ? (
                <CommandButton
                    iconProps={item.iconProps}
                    text={item.text}
                    onClick={item.onClick}
                    styles={item.styles}
                />
            ) : (
                <></>
            );
        };

        return (
            <div className="MobileReleaseStageDetails-root">
                <div style={divStyles(stage?.completionIndicator)}>
                    {expanded ? (
                        <div>
                            <Stack tokens={stackTokens}>
                                <Stack
                                    horizontal
                                    tokens={stackTokens}
                                    style={{
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        iconProps={{
                                            iconName: expanded
                                                ? 'ChevronDownMed'
                                                : 'ChevronRightMed',
                                        }}
                                        onClick={expandRelease}
                                    />

                                    <Text variant="medium">
                                        {stage?.stageName}
                                    </Text>
                                </Stack>
                                <CompletionIndicatorIcon
                                    completionIndicator={
                                        stage?.completionIndicator!
                                    }
                                    theme={theme}
                                    showText
                                />
                            </Stack>

                            <Stack
                                tokens={stackTokens}
                                style={{
                                    border:
                                        'solid 1px ' +
                                        theme.palette.neutralLighterAlt,
                                    background: theme.palette.neutralLighterAlt,
                                    padding: '10px',
                                    margin: '10px 0px',
                                }}
                            >
                                <Text variant="medium">Deployments</Text>
                                {stage?.deployments.map((deployment) => {
                                    return (
                                        <div
                                            style={{
                                                border:
                                                    'solid 1px ' +
                                                    theme.palette.neutralLight,
                                            }}
                                        >
                                            <MobileReleaseStageEntityInfo
                                                entity={deployment}
                                                setSelected={props.setSelected}
                                                showPanel={props.showPanel}
                                                menuItems={[
                                                    {
                                                        key: 'showMetadata',
                                                        text: 'Show Metadata',
                                                        iconProps: {
                                                            iconName: 'Info',
                                                        },
                                                        onClick: () =>
                                                            showMetadataPanelClick(
                                                                deployment
                                                            ),
                                                    },
                                                    {
                                                        key: 'general',
                                                        text: 'General Deployment Data',
                                                        iconProps: {
                                                            iconName:
                                                                'KnowledgeArticle',
                                                        },
                                                        onRender:
                                                            renderAgrmButton,
                                                        onClick: () =>
                                                            showAgrmtDataClick(
                                                                deployment,
                                                                AGRMDataType.General
                                                            ),
                                                    },
                                                    {
                                                        key: 'orchestrations',
                                                        text: 'Deployment Orchestrations',
                                                        iconProps: {
                                                            iconName:
                                                                'ServerProcesses',
                                                        },
                                                        onRender:
                                                            renderAgrmButton,
                                                        onClick: () =>
                                                            showAgrmtDataClick(
                                                                deployment,
                                                                AGRMDataType.Orchestrations
                                                            ),
                                                    },
                                                    {
                                                        key: 'states',
                                                        text: 'Deployment States',
                                                        iconProps: {
                                                            iconName: 'Org',
                                                        },
                                                        onRender:
                                                            renderAgrmButton,
                                                        onClick: () =>
                                                            showAgrmtDataClick(
                                                                deployment,
                                                                AGRMDataType.States
                                                            ),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    );
                                })}
                            </Stack>

                            <Stack
                                tokens={stackTokens}
                                style={{
                                    border:
                                        'solid 1px ' +
                                        theme.palette.neutralLighterAlt,
                                    background: theme.palette.neutralLighterAlt,
                                    padding: '10px',
                                    margin: '10px 0px',
                                }}
                            >
                                <Text variant="medium">Builds</Text>
                                {stage?.builds.map((build) => {
                                    return (
                                        <div
                                            style={{
                                                border:
                                                    'solid 1px ' +
                                                    theme.palette.neutralLight,
                                            }}
                                        >
                                            <MobileReleaseStageEntityInfo
                                                entity={build}
                                                setSelected={props.setSelected}
                                                showPanel={props.showPanel}
                                                menuItems={[
                                                    {
                                                        key: 'showMetadata',
                                                        text: 'Show Metadata',
                                                        iconProps: {
                                                            iconName: 'Info',
                                                        },
                                                        onClick: () => {
                                                            showMetadataPanelClick(
                                                                build
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    );
                                })}
                            </Stack>

                            <Stack horizontal>
                                {stage?.submittedBy && (
                                    <DocumentCardActivity
                                        styles={activityStyles}
                                        activity={'Submitted'}
                                        people={[
                                            {
                                                name: stage.submittedBy,
                                                profileImageSrc: '', //get from graph?
                                            },
                                        ]}
                                    />
                                )}
                                {stage?.approvedBy && (
                                    <DocumentCardActivity
                                        styles={activityStyles}
                                        activity={'Approved'}
                                        people={[
                                            {
                                                name: stage.approvedBy,
                                                profileImageSrc: '', //get from graph?
                                            },
                                        ]}
                                    />
                                )}
                                {stage?.monitorId && (
                                    <span>Monitor Id: {stage.monitorId}</span>
                                )}
                            </Stack>
                        </div>
                    ) : (
                        <div>
                            <Stack
                                horizontal
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    styles={{
                                        root: {
                                            paddingRight: '5px',
                                        },
                                    }}
                                    iconProps={{
                                        iconName: expanded
                                            ? 'ChevronDownMed'
                                            : 'ChevronRightMed',
                                    }}
                                    onClick={expandRelease}
                                />
                                <ReleaseStageCompactDetails stage={stage} />
                            </Stack>
                        </div>
                    )}
                </div>
                <AgrmDetailsPanel
                    deployment={selected as Deployment}
                    isOpen={isAgrmPanelOpen}
                    dismissPanel={hideAgrmPanel}
                    selectedOption={selectedAgrmOption ?? AGRMDataType.General}
                />
            </div>
        );
    };
