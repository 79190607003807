import {
    getConfig,
    GlobalConfigProperties,
} from '../api/ABHub/ABHubGlobalConfig';
import { InvalidParamError } from '../errors/InvalidParamError';
import { CloudEnvironment } from '../models/CloudEnvironment';
import { Guid } from '../models/Guid';

export const routeKeys = {
    Home: '/',
    Services: '/Services',
    Organizations: '/Organizations',
    Components: '/Components',
    Divisions: '/Divisions',
    Features: {
        Base: '/Features',
        Releases: {
            Base: '/ReleaseStatus',
            Latest: '/ReleaseStatus/Latest',
            Search: '/ReleaseStatus/Release/Search',
            ArtifactSearch: '/ReleaseStatus/Artifact/Search',
        },
        Builds: {
            Base: '/BuildStatus',
            Latest: '/BuildStatus/Latest',
            Search: '/BuildStatus/Search',
        },
    },
    Administration: '/Administration',
};

export const getNavRoute = (pathname: string): string | undefined => {
    if (pathname === '/') {
        return routeKeys.Home;
    }
    if (
        pathname.startsWith('/Services') ||
        pathname.startsWith('/Components')
    ) {
        return routeKeys.Services;
    }
    if (
        pathname.startsWith('/Divisions') ||
        pathname.startsWith('/Organizations')
    ) {
        return routeKeys.Organizations;
    }
    if (pathname.startsWith('/ReleaseStatus')) {
        return routeKeys.Features.Releases.Base;
    }
    if (pathname.startsWith('/BuildStatus')) {
        return routeKeys.Features.Builds.Base;
    }
    if (pathname.startsWith('/Administration')) {
        return routeKeys.Administration;
    }
    return undefined;
};

export interface ServiceReleaseDetailsRouteParams {
    serviceId: string;
    releaseCorrelationId: string;
}

export interface BuildDetailsRouteParams {
    serviceId: string;
    buildInstanceCorrelationId: string;
}

export interface ArtifactDetailsRouteParams {
    serviceId: string;
    artifactId: string;
}

export interface ReleaseBuildDetailsRouteParams {
    serviceId: string;
    releaseCorrelationId: string;
    buildInstanceCorrelationId: string;
}

export interface ServiceDetailsRouteParams {
    serviceId: string;
}

export interface ReleaseDetailsRouteParams {
    account?: string;
    project?: string;
    releaseid?: string;
    environment?: CloudEnvironment;
}

export interface MultistageReleaseLatestRouteParams {
    count?: string;
}

export interface ReleaseLatestRouteParams {
    count?: string;
}

export interface SearchRouteParams {
    releaseId?: string;
}

export interface ArtifactRouteParams {
    artifactId?: string;
}

export interface ArtifactDisplayRouteParams {
    artifactId: string;
    environment: string;
    id: string;
}

export interface ListByServiceParams {
    service: string;
}

export const validateRouteParams = (
    account?: string,
    project?: string,
    releaseid?: string,
    environment?: CloudEnvironment
): ReleaseDetailsRouteParams => {
    if (!account) {
        throw new InvalidParamError('account ID', account);
    }
    if (project === 'undefined' || project === 'null') {
        throw new InvalidParamError('project ID', project);
    }
    if (releaseid) {
        const id = parseInt(releaseid);
        if (!Number.isInteger(id)) {
            throw new InvalidParamError('release ID', releaseid);
        }
    }

    return { account, project, releaseid, environment };
};

export const getAdoAccountUrl = (accountName: string): string => {
    let url: string = process.env.REACT_APP_ADO_URL_ACCOUNT || '';
    return url.replace('{accountName}', accountName ? accountName : ''); // can't use account GUID for ADO url
};

export const getAdoProjectUrl = (
    accountName: string,
    projectName: string,
    project: Guid
): string => {
    let url: string = process.env.REACT_APP_ADO_URL_PROJECT || '';
    if (projectName || project) {
        url = url.replace('{accountName}', accountName ? accountName : ''); // can't use account GUID for ADO url
        url = url.replace(
            '{projectName}',
            projectName ? projectName : project ? project : ''
        );
    }
    return url;
};

export const getAdoReleaseUrl = (
    accountName: string,
    projectName: string,
    project: Guid,
    releaseId: number
): string => {
    let url: string = process.env.REACT_APP_ADO_URL_RELEASE || '';
    if (releaseId) {
        url = url.replace('{accountName}', accountName ? accountName : ''); // can't use account GUID for ADO url
        url = url.replace(
            '{projectName}',
            projectName ? projectName : project ? project : ''
        );
        url = url.replace('{releaseId}', releaseId.toString() || '');
    }
    return url;
};

export const getNewYamlReleaseUrl = (
    accountName: string,
    projectName: string,
    project: Guid,
    releaseId: number
): string => {
    let url: string = process.env.REACT_APP_YAML_URL_RELEASE || '';
    if (releaseId) {
        url = url.replace('{accountName}', accountName ? accountName : ''); // can't use account GUID for ADO url
        url = url.replace(
            '{projectName}',
            projectName ? projectName : project ? project : ''
        );
        url = url.replace('{releaseId}', releaseId.toString() || '');
    }
    return url;
};

export const getServiceTreeUrl = (serviceTreeGuid: Guid): string => {
    let url: string = process.env.REACT_APP_SERVICE_TREE_URL || '';
    url = url.replace(
        '{serviceTreeUrl}',
        getConfig(GlobalConfigProperties.ServiceTreeUrl)
    );
    url = url.replace('{serviceId}', serviceTreeGuid);
    return url;
};

export const getAccountPath = (accountName: string, account: Guid): string => {
    return `/LegacyReleaseStatus/${accountName || account}`;
};

export const getProjectPath = (
    accountName: string,
    account: Guid,
    projectName: string,
    project: Guid
): string => {
    return `/LegacyReleaseStatus/${accountName || account}/${
        projectName || project
    }`;
};

export const getServicePath = (
    serviceName: string,
    serviceTreeGuid: Guid
): string => {
    return `/LegacyReleaseStatus/Service/${serviceName || serviceTreeGuid}`;
};

export const getArtifactPath = (artifactId: string): string => {
    return `/Artifact/${artifactId}`;
};
