import React, { useCallback, useEffect, useState, RefObject } from 'react';
import { ReleaseList } from './Display/ReleaseList';
import { usePromise } from '../../../Common/hooks/usePromise';
import { usePrevious } from '../../../Common/hooks/usePrevious';
import { CancelToken } from 'axios';
import { getReleaseList } from '../../api/AGSSApi';
import { ReleaseSearchResult } from '../../models/Release';
import { ErrorPage } from '../../../HubLayout/components/ErrorPage';
import { emitPageLoadMetric } from '../../../Common/util/metricsUtil';

interface IReleaseLatestListPageProps {
    scrollBarRef: RefObject<HTMLDivElement>;
    scrollTop: number;
}
export const ReleaseLatestListPage: React.FC<IReleaseLatestListPageProps> = (
    props: IReleaseLatestListPageProps
) => {
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [selectedEnvironments, setSelectedEnvironments] = useState<string[]>(
        []
    );
    const [selectedDeploymentTypes, setSelectedDeploymentTypes] = useState<
        string[]
    >([]);
    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hideButton, setHideButton] = useState<boolean>(false);
    const prevPageNumber = usePrevious(pageNumber);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const getData = useCallback(
        async (cancelToken?: CancelToken) => {
            const releases: ReleaseSearchResult[] = await getReleaseList(
                selectedServices,
                undefined,
                selectedEnvironments,
                selectedDeploymentTypes,
                selectedProjects,
                searchValue,
                startDate,
                endDate,
                pageNumber,
                cancelToken
            );
            // don't reorder, the server orders depending on params
            return releases;
        },
        // When dependency array changes, usePromise will clear data and restart API.
        [
            selectedServices,
            selectedEnvironments,
            selectedDeploymentTypes,
            selectedProjects,
            searchValue,
            startDate,
            endDate,
            pageNumber,
        ]
    );
    const [heightDepth, setHeightDepth] = useState<number>(0);
    const [result, error, isLoaded] = usePromise(getData, true);
    // const data = result || [];
    const [data, setData] = useState<ReleaseSearchResult[]>([]);

    useEffect(() => {
        if (pageNumber === 1 && result) {
            if (prevPageNumber !== pageNumber) {
            } else {
                if (result.length !== 0) {
                    setData(result);
                }
                //otherwise return an empty array
                else {
                    result.splice(0, result.length);
                    setData(result);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);

    useEffect(() => {
        setHeightDepth(0);
    }, [selectedServices, selectedEnvironments, selectedProjects, startDate, endDate, searchValue]);

    useEffect(() => {
        if (pageNumber > 1 && result) {
            if (result.length === 0) {
                setHideButton(true);
            } else {
                const newList = data.concat(result);
                setData(newList);
            }
            setHideButton(false);
        }
    }, [pageNumber && result]);

    const scrollMultiplier = 1.1;

    useEffect(() => {
        setTimeout(() => {
            if (props.scrollBarRef.current) {
                props.scrollBarRef.current.scrollTo(
                    0,
                    scrollMultiplier * heightDepth
                );
            }
        }, 50);
    }, [data]);

    useEffect(() => {
        if (props.scrollTop > heightDepth) {
            setHeightDepth(props.scrollTop);
        }
    }, [props.scrollTop]);

    // metric to measure inital page load time
    useEffect(() => {
        var emittedSuccessfully = emitPageLoadMetric(
            'ReleaseLatest',
            process.env.REACT_APP_BUILD_VERSION,
            isFirstLoad
        );

        setIsFirstLoad(emittedSuccessfully);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return error ? (
        <ErrorPage error={error} />
    ) : (
        <div
            className="ReleaseLatestListPage-root"
            style={{
                padding: '2px 2px 0px 2px',
                border: 'none',
            }}
        >
            <ReleaseList
                list={data}
                isLoaded={isLoaded}
                hideButton={hideButton}
                setSearchValue={setSearchValue}
                setSelectedServices={setSelectedServices}
                setSelectedEnvironments={setSelectedEnvironments}
                setSelectedDeploymentTypes={setSelectedDeploymentTypes}
                setSelectedProjects={setSelectedProjects}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                //serviceType={'Service'}
            />
        </div>
    );
};
